import { Storage } from '@ionic/storage';

const storageSingelton = () => {
	return {
		async install(app) {
			const storage = new Storage();
			const storageInstance = await storage.create();
			app.config.globalProperties.$ionicStorage = storageInstance;
		},
	};
};

const createdIonicStore = storageSingelton();

export default createdIonicStore;
