'use strict';
import { createStore } from 'vuex';

import loginData from "@/store/modules/login.js";
import studentData from "@/store/modules/students.js";
import slidesData from "@/store/modules/slides.js";

const store = createStore({
    modules: {
        login:loginData,
        student:studentData,
        slides:slidesData,
    },
    state() {
        return {
            staticURL: 'https://ema.ualg.pt/emastatic/',
            //staticURL: 'http://localhost:8000/emastatic/',
        }}
})
export default store;
