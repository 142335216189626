
const slidesData = {
    namespaced: true,
    state() {
        const slide = JSON.parse(localStorage.getItem('slide'));
        const initialSlide = slide 
           ? slide 
           : { key:"0001",
               nextRouter:'0101',
               isAnswered: false,
               answer:0,
               inquiryCompleted:false };
        return{
            slide: initialSlide,
            icons: {soundBtn: "icons/som.svg", goBtn: "icons/avancar.svg"},
            media: {alert: "audio/alert.m4a"}
        }
    },
    mutations: {
        SET_SLIDE(state, data){
            console.log("DATA SLIDE:", data);
            state.slide.key = data.key;
            state.slide.nextRouter = data.nextRouter;
            state.slide.isAnswered = false;
            state.slide.answer = 0;
            localStorage.setItem('slide', JSON.stringify(state.slide))
        },
        SET_ANSWER(state, answer){
            state.slide.answer = answer;
            state.slide.answered = true;
            localStorage.setItem('slide', JSON.stringify(state.slide))
        },
        SET_FIRST_SLIDE(state){
            state.slide.key="0001";
            state.slide.nextRouter='0101';
            state.slide.isAnswered=false;
            state.slide.answer=0;
            state.slide.inquiryCompleted=false;
            localStorage.setItem('slide', JSON.stringify(state.slide))
        }
    },
    actions: {
    },
    getters: {
        goButton(state) {return state.icons.goBtn},
        soundButton(state) {return state.icons.soundBtn},
        getSlide(state) {return state.slide},
        selectAlert(state) {return state.media.alert},
    },
}

export default slidesData;
