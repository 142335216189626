
// const student = JSON.parse(localStorage.getItem('student'));
// const initialState = student
//   ? student
//   : { studentID: "", 
//       student_sex: "", 
//       teacher_sex: "",
//     };
const studentData = {
    namespaced: true,
    state() {
        const student = JSON.parse(localStorage.getItem('student'));
        const initialSlide = student 
           ? student 
           : {             
               studentID:'',
               student_sex:'',
               teacher_sex: '' 
            };
        console.log("store:", student);
        return{
            ...initialSlide
        }
    },
    mutations: {
        INIT(state, data){
                state.studentID = data.uuid;
                state.student_sex = data.student_sex;
                localStorage.setItem('student', JSON.stringify(state))
        },
        TEACHER(state, teacher_sex) {
            state.teacher_sex = teacher_sex;
            localStorage.setItem('student', JSON.stringify(state))
        },
    },
    actions: {
    },
    getters: {
        studentUUID(state) {return state.studentID },
        studentSex(state) {return state.student_sex },
        teacherSex(state) {return state.teacher_sex },
    },
}

export default studentData;

