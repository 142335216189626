import { createRouter, createWebHistory } from '@ionic/vue-router';
//import Home from '@/paginas/01.inicio/HomePage.vue';
//import LoginForm from "@/componentes/01.inicio/LoginForm.vue";
//import DadosCriancaForm from "@/componentes/01.inicio/DadosCriancaForm.vue";
//import InicioQuestionario from "@/paginas/01.inicio/InicioQuestionario.vue";

const routes = [
  {
    path: '/',
    redirect: '/homepage',
  },
  //----------------------------------
  //------- homepages ----------------
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/homepages/LoginPage.vue'),
//    component: LoginForm,
  },
  {
      path: '/ficha-tecnica',
      name: 'FichaTecnica',
      component: () => import('@/views/pages/homepages/GeneralInfo.vue'),
  },
  {
      path: '/homepage',
      name: 'HomePage',
      component: () => import('@/views/pages/homepages/HomePage.vue'),
  },
  {
    path: '/novo-questionario',
    name: 'NovoQuestionario',
    component: () => import('@/views/pages/homepages/NewInquiry.vue'),
},
{
  path: '/recuperar-questionario',
  name: 'RecuperarQuestionario',
  component: () => import('@/views/pages/homepages/ResumeInquiry.vue'),
},
{
  path: '/enviar-desenho',
  name: 'EnviarDesenho',
  component: () => import('@/views/pages/homepages/SendChildDraw.vue'),
},
{
  path: '/desenho-enviado-sucesso',
  name: 'DesenhoEnviadoSucesso',
  component: () => import('@/views/pages/homepages/DesenhoEnviadoSucesso.vue'),
},
{
  path: '/mostrar-respostas',
  name: 'MostrarRespostas',
  component: () => import('@/views/pages/homepages/MostrarRespostas.vue'),
},
{
  path: '/profile',
  name: 'TeacherProfile',
  component: () => import('@/views/pages/homepages/TeacherProfile.vue'),
},
// -------------------------------------------
//    SLIDES
// -------------------------------------------
{
  path: '/slide0001',
  name: 'PresentationSlide',
  component: () => import('@/views/pages/slides01/PresentationSlide.vue'),
},
// -----
// BLOCO 1
{
  path: '/slide0101',
  name: 'Slide0101',
  component: () => import('@/views/pages/slides01/QuestionB01.vue'),
},
{
  path: '/slide0102',
  name: 'Slide0102',
  component: () => import('@/views/pages/slides01/QuestionB02.vue'),
},
{
  path: '/slide0103',
  name: 'Slide0103',
  component: () => import('@/views/pages/slides01/QuestionB03.vue'),
},
{
  path: '/slide0104',
  name: 'Slide0104',
  component: () => import('@/views/pages/slides01/QuestionB04.vue'),
},
{
  path: '/slide0105',
  name: 'Slide0105',
  component: () => import('@/views/pages/slides01/QuestionB05.vue'),
},
{
  path: '/slide0106',
  name: 'Slide0106',
  component: () => import('@/views/pages/slides01/QuestionB06.vue'),
},
//-----------------------------------
// BLOCO 2
{
  path: '/slide0201',
  name: 'Slide0201',
  component: () => import('@/views/pages/slides02/Question0201.vue'),
},
{
  path: '/slide0202',
  name: 'Slide0202',
  component: () => import('@/views/pages/slides02/Question0202.vue'),
},
{
  path: '/slide0203',
  name: 'Slide0203',
  component: () => import('@/views/pages/slides02/Question0203.vue'),
},
{
  path: '/slide0204',
  name: 'Slide0204',
  component: () => import('@/views/pages/slides02/Question0204.vue'),
},
{
  path: '/slide0205',
  name: 'Slide0205',
  component: () => import('@/views/pages/slides02/Question0205.vue'),
},
{
  path: '/slide0206',
  name: 'Slide0206',
  component: () => import('@/views/pages/slides02/Question0206.vue'),
},
{
  path: '/slide0207',
  name: 'Slide0207',
  component: () => import('@/views/pages/slides02/Question0207.vue'),
},
{
  path: '/slide0208',
  name: 'Slide0208',
  component: () => import('@/views/pages/slides02/Question0208.vue'),
},
{
  path: '/slide0209',
  name: 'Slide0209',
  component: () => import('@/views/pages/slides02/Question0209.vue'),
},
//------------------------------------
// BLOCO 3 (Professor/Professora)
{
  path: '/slide0301',
  name: 'Slide0301',
  component: () => import('@/views/pages/slides03/Question0301.vue'),
},
{
  path: '/slide0302',
  name: 'Slide0302',
  component: () => import('@/views/pages/slides03/Question0302.vue'),
},
{
  path: '/slide0303',
  name: 'Slide0303',
  component: () => import('@/views/pages/slides03/Question0303.vue'),
},
{
  path: '/slide0304',
  name: 'Slide0304',
  component: () => import('@/views/pages/slides03/Question0304.vue'),
},
{
  path: '/slide0305',
  name: 'Slide0305',
  component: () => import('@/views/pages/slides03/Question0305.vue'),
},
{
  path: '/slide0306',
  name: 'Slide0306',
  component: () => import('@/views/pages/slides03/Question0306.vue'),
},
{
  path: '/slide0307',
  name: 'Slide0307',
  component: () => import('@/views/pages/slides03/Question0307.vue'),
},
{
  path: '/slide0312',
  name: 'Slide0312',
  component: () => import('@/views/pages/slides03/Question0312.vue'),
},
{
  path: '/slide0313',
  name: 'Slide0313',
  component: () => import('@/views/pages/slides03/Question0313.vue'),
},
{
  path: '/slide0314',
  name: 'Slide0314',
  component: () => import('@/views/pages/slides03/Question0314.vue'),
},
{
  path: '/slide0315',
  name: 'Slide0315',
  component: () => import('@/views/pages/slides03/Question0315.vue'),
},
{
  path: '/slide0316',
  name: 'Slide0316',
  component: () => import('@/views/pages/slides03/Question0316.vue'),
},
{
  path: '/slide0317',
  name: 'Slide0317',
  component: () => import('@/views/pages/slides03/Question0317.vue'),
},
//---------------------------------
// BLOCO 4
{
  path: '/slide0401',
  name: 'Slide0401',
  component: () => import('@/views/pages/slides04/Question0401.vue'),
},
{
  path: '/slide0402',
  name: 'Slide0402',
  component: () => import('@/views/pages/slides04/Question0402.vue'),
},
{
  path: '/slide0403',
  name: 'Slide0403',
  component: () => import('@/views/pages/slides04/Question0403.vue'),
},
{
  path: '/slide0404',
  name: 'Slide0404',
  component: () => import('@/views/pages/slides04/Question0404.vue'),
},
{
  path: '/slide0405',
  name: 'Slide0405',
  component: () => import('@/views/pages/slides04/Question0405.vue'),
},
{
  path: '/slide0406',
  name: 'Slide0406',
  component: () => import('@/views/pages/slides04/Question0406.vue'),
},
{
  path: '/slide0407',
  name: 'Slide0407',
  component: () => import('@/views/pages/slides04/Question0407.vue'),
},
//---------------------------------------------
// BLOCO 5
{
  path: '/slide0501',
  name: 'Slide0501',
  component: () => import('@/views/pages/slides05/Question0501.vue'),
},
{
  path: '/slide0502',
  name: 'Slide0502',
  component: () => import('@/views/pages/slides05/Question0502.vue'),
},
{
  path: '/slide0503',
  name: 'Slide0503',
  component: () => import('@/views/pages/slides05/Question0503.vue'),
},
//-----------------------------------------------
// BLOCO 6
{
  path: '/slide0601',
  name: 'Slide0601',
  component: () => import('@/views/pages/slides06/Question0601.vue'),
},
{
  path: '/slide0602',
  name: 'Slide0602',
  component: () => import('@/views/pages/slides06/Question0602.vue'),
},
{
  path: '/slide0603',
  name: 'Slide0603',
  component: () => import('@/views/pages/slides06/Question0603.vue'),
},
{
  path: '/slide0604',
  name: 'Slide0604',
  component: () => import('@/views/pages/slides06/Question0604.vue'),
},
{
  path: '/slide0605',
  name: 'Slide0605',
  component: () => import('@/views/pages/slides06/Question0605.vue'),
},
{
  path: '/slide0606',
  name: 'Slide0606',
  component: () => import('@/views/pages/slides06/Question0606.vue'),
},
//---------------------------------------------------------------
// BLOCO 7
{
  path: '/slide0701',
  name: 'Slide0701',
  component: () => import('@/views/pages/slides07/Question0701.vue'),
},
{
  path: '/slide0702',
  name: 'Slide0702',
  component: () => import('@/views/pages/slides07/Question0702.vue'),
},
{
  path: '/slide0703',
  name: 'Slide0703',
  component: () => import('@/views/pages/slides07/SlideQuestion0703.vue'),
},
//---------------------------------------------------------------
// BLOCO 7
{
  path: '/slide0801',
  name: 'Slide0801',
  component: () => import('@/views/pages/slides08/Question0801.vue'),
},
{
  path: '/slide0802',
  name: 'Slide0802',
  component: () => import('@/views/pages/slides08/Question0802.vue'),
},
{
  path: '/slide0803',
  name: 'Slide0803',
  component: () => import('@/views/pages/slides08/Question0803.vue'),
},
{
  path: '/slide0804',
  name: 'Slide0804',
  component: () => import('@/views/pages/slides08/Question0804.vue'),
},
//----------------------------------------------------------------
//
{
    path: '/teste',
    name: 'Teste',
    component: () => import('@/views/pages/tests/SlideTest.vue'),
},

/*{
  path: "/test/:slideID",
  name: "testPage",
  component: BlocoPerguntas01,
  props: true
},
*/

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
