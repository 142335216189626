import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from '@/store/vuexStore.js';

import { IonicVue } from '@ionic/vue';
import { createAnimation } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.css';
import './theme/modal.css';

//navigator.serviceWorker.register("/sw.js");

/**/
const myAnimation = (baseEl, opts) => {
    const { enteringEl, leavingEl } = opts;
  
    const enteringPage = createAnimation('entering-page-animation')
      .addElement(enteringEl)
      .fromTo('opacity', 1, 0);
  
    const leavingPage = createAnimation('leaving-page-animation')
      .addElement(leavingEl)
      .fromTo('opacity', 0, 1);
  
    return createAnimation('root-transition')
      .duration(5000)
      .iterations(Infinity)
      .easing('ease-in-out')
      .addAnimation([enteringPage,leavingPage]);
  }
/**/
import setupInterceptors from '@/services/setupInterceptors.js';
//setupInterceptors(store);
setupInterceptors();

import ionicStorage from '@/store/ionicStorage';

const app = createApp(App)
  .use(IonicVue, {animated: true, navAnimation: myAnimation})
  //.use(IonicVue)
  .use(store)
  .use(ionicStorage)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});
