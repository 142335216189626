// const user = JSON.parse(localStorage.getItem('user'));
// console.log("USER", user);
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: {
//     accessToken: '',
//     refreshToken: '',
//     loggedIn: true,
//     loginError: false
//   } };

const loginData = {
    namespaced: true,
    state() { 
        const user = JSON.parse(localStorage.getItem('user'));
        const initialSlide = user 
           ? user 
           : {             
            accessToken: "",
            refreshToken: "",
            loggedIn: false,
            loginError: false
            };

        return{
           ...initialSlide
        }
    },
    mutations: {
        LOGIN_SUCCESS(state, data){
            state.accessToken = data.access,
            state.refreshToken = data.refresh,
            state.loggedIn = true,
            state.loginError = false;
            localStorage.setItem('user', JSON.stringify(state))
        },
        LOGIN_ERROR(state){
            state.accessToken = '';
            state.refreshToken = '';
            state.loggedIn = false;
            state.loginError = true;
        },
        REFRESH_TOKEN(state, accessToken) {
            state.loggedIn = true;
            state.accessToken = accessToken
          },
        LOGOUT(state){
            state.accessToken = '';
            state.refreshToken = '';
            state.loggedIn = false;
            state.loginError = false;
        }
    },
    getters: {
        isAuth(state) { return state.loggedIn },
    },
    
}
export default loginData;
