<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';

//import EventBus from "@/services/common/EventBus.js";

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet, 
  },
  // methods:{
  //    logOut() {
  //       this.$store.dispatch('auth/logout');
  //       this.$router.replace('/login');
  //    },
  // },
  // mounted() {
  //   EventBus.on("logout", () => {
  //       this.logOut();
  //   });
  // },
  // beforeUnmount() {
  //   EventBus.remove("logout");
  // },
};
</script>

<style>
html {
	font-size: calc(9px + 0.75vw);
  
}

@font-face {
  font-family: "Cookie";
  src: local("Cookie"),   url(./assets/fonts/Cookie-Regular.ttf) format("truetype");}


ion-app {
    background-color: white;
  }

body{
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #9ADCF4 ;
  font-family: "Cookie" !important;

  --background: #9ADCF4;
  background-color: #9ADCF4;

}
</style>
