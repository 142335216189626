
import axios from "axios";

const instance = axios.create({
  baseURL: "https://ema.ualg.pt/api/",
  //baseURL: "http://localhost:8000/api/",
  //baseURL: "http://localhost:9000/api/",
  headers: {

  },
});
export default instance;
